.about-page {
  margin: 35px 30% 15px;
  background-color: #525252
}

.page-title {
  padding: '15px 0 0';
}

.about-content {
  padding: '0 15px 15px';
}
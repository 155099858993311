.filter-panel {
  float: left;
  width: 15%
}

.result-panel {
  float: left;
  width: 15%
}

.results {
  float: right; 
  width: 70%;
  height: 500px;
  overflow-y: scroll
}
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

body {
  margin: 0 0%;
  font-family: "DM Sans";
  background: #2c2c2c;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2,h3,h4 {
  font-weight: 400;
}

.App {
  margin: 0px;
  padding: 0px;
}

.home-card-container {
  padding: 0% 15% 5%
}

/* .post-card {
  padding: 10px;
  border-bottom: 1px solid;
} */

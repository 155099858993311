@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);
body {
  margin: 0 0%;
  font-family: "DM Sans";
  background: #2c2c2c;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2,h3,h4 {
  font-weight: 400;
}

.App {
  margin: 0px;
  padding: 0px;
}

.home-card-container {
  padding: 0% 15% 5%
}

/* .post-card {
  padding: 10px;
  border-bottom: 1px solid;
} */

.featured-blog-jumbo {
  padding-top: 0;
  padding-bottom: 0;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  background-size: cover;
  background-position: center center;
  height: 400px
}

.jumbo-interior {
  padding: 15px;
  width: 40%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5)
}

hr {
  width: 80%;
  margin-left: 0
}

h4 {
  padding-bottom: 30px
}
.review-text {
  padding: 30px;
  margin-left: 150px;
  margin-right: 150px;
  background-color: #525252 
}

.header-image-text {
  text-align: center;
  padding: 15px;
  width: 40%;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5)
}

.header-image { 
  background-size: cover;
  background-position: center center;
  height: 400px
}
.about-page {
  margin: 35px 30% 15px;
  background-color: #525252
}

.page-title {
  padding: '15px 0 0';
}

.about-content {
  padding: '0 15px 15px';
}
.filter-panel {
  float: left;
  width: 15%
}

.result-panel {
  float: left;
  width: 15%
}

.results {
  float: right; 
  width: 70%;
  height: 500px;
  overflow-y: scroll
}
